.lst-kix_nv8tu3dmspa1-0 > li:before {
  content: "\0025cf  ";
}

ul.lst-kix_nv8tu3dmspa1-0 {
  list-style-type: none;
}

.lst-kix_nv8tu3dmspa1-1 > li:before {
  content: "\0025cb  ";
}

ul.lst-kix_nv8tu3dmspa1-4 {
  list-style-type: none;
}

ul.lst-kix_nv8tu3dmspa1-3 {
  list-style-type: none;
}

ul.lst-kix_nv8tu3dmspa1-2 {
  list-style-type: none;
}

ul.lst-kix_nv8tu3dmspa1-1 {
  list-style-type: none;
}

ul.lst-kix_nv8tu3dmspa1-8 {
  list-style-type: none;
}

ul.lst-kix_nv8tu3dmspa1-7 {
  list-style-type: none;
}

li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

ul.lst-kix_nv8tu3dmspa1-6 {
  list-style-type: none;
}

.lst-kix_nv8tu3dmspa1-7 > li:before {
  content: "\0025cb  ";
}

ul.lst-kix_nv8tu3dmspa1-5 {
  list-style-type: none;
}

.lst-kix_nv8tu3dmspa1-6 > li:before {
  content: "\0025cf  ";
}

.lst-kix_nv8tu3dmspa1-4 > li:before {
  content: "\0025cb  ";
}

.lst-kix_nv8tu3dmspa1-8 > li:before {
  content: "\0025a0  ";
}

.lst-kix_nv8tu3dmspa1-5 > li:before {
  content: "\0025a0  ";
}

.lst-kix_nv8tu3dmspa1-2 > li:before {
  content: "\0025a0  ";
}

.lst-kix_nv8tu3dmspa1-3 > li:before {
  content: "\0025cf  ";
}

ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c8 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c1 {
  padding-top: 0pt;
  padding-bottom: 15pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c2 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
   
  font-style: normal;
}

.c4 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 27pt;
   
  font-style: normal;
}

.c0 {
  color: #333333;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 22.5pt;
   
  font-style: normal;
}

.c3 {
  padding-top: 0pt;
  padding-bottom: 14pt;
  line-height: 1.1;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c5 {
  background-color: #ffffff;
  background-color: #ffffff;
}

.c9 {
  color: #333333;
  font-size: 12pt;
}

.c6 {
  color: inherit;
  text-decoration: inherit;
}

.c7 {
  padding: 0;
  margin: 0;
}

.title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

li {
  color: #000000;
  font-size: 11pt;
   
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
   
}

h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}

h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
   
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
