@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(/src/assets/font/stylesheet.css);

* {
  font-family: "Rubik";
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.slick-slider .slick-list {
  overflow: visible;
}

